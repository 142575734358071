import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '@/store/auth'
const routes: Array<RouteRecordRaw> = [
	{
		path: '',
		redirect: '/identification/index',
		component: () => import('@/modules/layouts/main/main.vue'),
		children: [
			{ path: '/identification/index', component: () => import('@/modules/pages/identification/index/index.vue'), meta: { auth: true } },
			{ path: '/identification/page/:id?', component: () => import('@/modules/pages/identification/page/page.vue'), meta: { auth: true } },
			{ path: '/profile', component: () => import('@/modules/pages/profile/profile/profile.vue'), meta: { auth: true } }
		]
	},
	{
		path: '',
		component: () => import('@/modules/layouts/empty.vue'),
		children: [{ path: '/auth/login', component: () => import('@/modules/pages/auth/login/login.vue'), meta: { auth: false } }]
	}
]

const router = () => {
	const router = createRouter({
		history: createWebHistory(process.env.BASE_URL),
		routes
	})
	const authStore = useAuthStore()
	router.beforeEach((to, from, next) => {
		const requiredAuth = to.matched.some(r => r.meta.auth)
		const token = authStore.token
		if (requiredAuth && !token) next('/auth/login')
		else next()
	})
	return router
}

export default router
